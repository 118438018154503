import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import { formatNumberWithOutDecimal } from '../utils/utils'; // Assuming you have this utility function

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: '#000000', color: '#01FF85', padding: '10px', borderRadius: '5px', border: "2px solid #494949" }}>
                <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>{`Years Staked: ${payload[0].payload.range}`}</p>
                <div style={{ color: 'white' }}>
                    <p style={{ margin: '3px 0' }}>{`Number of Stakers: ${formatNumberWithOutDecimal(payload[0].value)}`}</p>
                </div>
            </div>
        );
    }
    return null;
};

const StakingDistributionChart = ({ data }) => {
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [clickedIndex, setClickedIndex] = useState(null); // Reintroduce this state

    const COLORS = ['#23DC8E', '#179C65', '#106F48', '#FDB366', '#0047CA', '#88018B', '#FFD700'];

    useEffect(() => {
        const processData = () => {
            try {
                const processedData = data.ranges.map((range, index) => ({
                    range: range[1] === Infinity || range[1] === null ? `${range[0]}+` : `${range[0]}-${range[1]}`,
                    frequency: data.frequencies[index]
                }));
                setChartData(processedData);
                setIsLoading(false);
            } catch (e) {
                console.error("An error occurred while processing the data: ", e);
                setError(e.message);
                setIsLoading(false);
            }
        };

        processData();

        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [data]);

    if (isLoading) return <div style={{ color: '#FFFFFF' }}>Loading...</div>;
    if (error) return <div style={{ color: '#FF0000' }}>Error: {error}</div>;

    const handlePieClick = (index) => {
        if (!isMobile) {
            setClickedIndex(index);
            setTimeout(() => setClickedIndex(null), 2000);
        }
    };

    const renderChart = () => (
        <ResponsiveContainer width="100%" height={isMobile ? 250 : 400}>
            <PieChart>
                <Pie
                    data={chartData}
                    dataKey="frequency"
                    nameKey="range"
                    cx="50%"
                    cy="50%"
                    outerRadius={isMobile ? "80%" : 150}
                    fill="#8884d8"
                    label={({ range }) => (isMobile ? null : range)}
                    onClick={(data, index) => handlePieClick(index)}
                >
                    {chartData.map((entry, index) => (
                        <Cell 
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                            stroke="none"
                            strokeWidth={0}
                            style={{
                                outline: 'none',
                                transform: !isMobile && clickedIndex === index ? 'scale(1.1)' : 'scale(1)',
                                transformOrigin: 'center',
                                transition: 'transform 0.2s ease-in-out'
                            }}
                        />
                    ))}
                </Pie>
                {!isMobile && <Tooltip content={<CustomTooltip />} />}
            </PieChart>
        </ResponsiveContainer>
    );

    const renderLegend = () => (
        <div style={{ marginTop: '20px', maxHeight: '150px', overflowY: 'auto' }}>
            {chartData.map((entry, index) => (
                <div key={entry.range} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <div style={{ width: '12px', height: '12px', backgroundColor: COLORS[index % COLORS.length], marginRight: '8px' }}></div>
                    <span style={{ color: '#FFFFFF', fontSize: '12px' }}>{`${entry.range}: ${formatNumberWithOutDecimal(entry.frequency)}`}</span>
                </div>
            ))}
        </div>
    );

    return (
        <div>
            {renderChart()}
            {isMobile && renderLegend()}
            <div style={{ textAlign: 'center', fontStyle: 'italic', marginTop: '10px', color: '#FFFFFF', fontSize: isMobile ? '12px' : '14px' }}>
                Distribution of Stakers by Years Staked
            </div>
        </div>
    );
};

export default StakingDistributionChart;