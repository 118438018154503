import React, { useState, useEffect } from 'react';
import StakingHistogramChart from './stakingDistributionChart';
import PowerMultiplierDistributionChart from './powerMultiplierDistributionChart';
import api_url from "./../../config/api_url.json";
import "./../../css/staking/stakingView.css";

const base_api_url = api_url.base_api_url;

const StakingChart = () => {
    const [chartData, setChartData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${base_api_url}/get_stake_info`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setChartData(data);
                setIsLoading(false);
            } catch (e) {
                console.error("An error occurred while fetching the data: ", e);
                setError(e.message);
                setIsLoading(false);
            }
        };

        fetchData();

        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (isLoading) {
        return <div style={{ color: '#FFFFFF' }}>Loading...</div>;
    }

    if (error) {
        return <div style={{ color: '#FF0000' }}>Error: {error}</div>;
    }

    return (
        <div className={`staking_chart_container ${isMobile ? 'mobile' : ''}`}>
            <div className="staking_chart_item">
                <div className="staking_chart_background">
                    <h2 className="chartheading">
                        Stake Time Distribution For Stakers
                    </h2>
                    {chartData && <StakingHistogramChart data={chartData.stake_time} />}
                </div>
            </div>

            <div className="staking_chart_item">
                <div className="staking_chart_background">
                    <h2 className="chartheading">
                        Power Multiplier Distribution For Stakers
                    </h2>
                    {chartData && <PowerMultiplierDistributionChart data={chartData.power_multiplier} />}
                </div>
            </div>
        </div>
    );
};

export default StakingChart;